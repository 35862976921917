<template>
  <v-select
    :label='$t("model.name.event")'
    class="white"
    :items="data"
    :item-text="events"
    item-value="id"
    v-model="id"
    :loading="api.isLoading"
    placeholder="Event"
    :rules="rules"
  ></v-select>
</template>

<script>
import i18n from '@/plugins/i18n'
import { mapState } from "vuex";
export default {
  props: ["callbackSelect", "callbackError"],
  data: () => ({
     rules : [v => !!v || i18n.t("rules.event")],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
  }),
computed: {
   ...mapState({
      school: (state) => state.school.data,
    }),
      inputVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    },
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.item = resp[0];
      this.id = resp[0].id;
    };
    //EOC
  },
  watch: {
    id: function (val) {
      this.id = val;
      this.item = this.$_.find(this.data, { id: this.id });
      this.callbackSelect(this.item);
    },
  },
  methods: {
    fetch() {
      this.api.url =
        this.$api.servers.backend +
        "/v1/"+this.$_getLocale()+"/data/event?schoolId="+this.school.id+'&classroomId='+this.$route.params.parentId;
      this.$api.fetch(this.api);
    },
    events: (item) => item.name,
  },
  beforeMount() {
    this.fetch();
  },
};
</script>
